:root {
  /* PRIMARY BF (Blue) */
  --color-primary-700: #002ea2;
  --color-primary-600: #3358b5;
  --color-primary-500: #6682c7;
  --color-primary-400: #99abda;
  --color-primary-300: #ccd5ec;

  /* SECONDARY (Sky Blue / Light Blue) */
  --color-secondary-700: #dbeefe;
  --color-secondary-600: #e2f1fe;
  --color-secondary-500: #e9f5fe;
  --color-secondary-400: #f1f8ff;
  --color-secondary-300: #f8fcff;

  /* ACCENT A (Orange) */
  --color-accent-a-700: #ffaa50;

  /* ACCENT B (Light Orange) */
  --color-accent-b-700: #faddd0;
  --color-accent-b-600: #fbe4d9;
  --color-accent-b-500: #fcebe3;
  --color-accent-b-400: #fdf1ec;
  --color-accent-b-300: #fef8f6;

  /* ERROR */
  --color-error-700: #d4564a;
  --color-error-300: #fad7d5;

  /* GREYSCALE */
  --color-black: #000000;
  --color-white: #ffffff;

  --color-grey-550: #747474;
  --color-grey-180: #d2d2d2;
  --color-grey-40: #f5f5f5;
}

@font-face {
  font-family: "Finlandica";
  src: url("/fonts/finlandica-bold.eot") format("embedded-opentype"),
    url("/fonts/finlandica-bold.woff2") format("woff2"),
    url("/fonts/finlandica-bold.woff") format("woff"),
    url("/fonts/finlandica-bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: "Finlandica";
  src: url("/fonts/Finlandica-Medium.otf") format("opentype"),
    url("/fonts/Finlandica-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
} */

@font-face {
  font-family: "Finlandica";
  src: url("/fonts/finlandica-regular.eot") format("embedded-opentype"),
    url("/fonts/finlandica-regular.woff2") format("woff2"),
    url("/fonts/finlandica-regular.woff") format("woff"),
    url("/fonts/finlandica-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Finlandica";
  src: url("/fonts/finlandica-bolditalicWeb.woff2") format("woff2"),
    url("/fonts/finlandica-bolditalicWeb.woff") format("woff"),
    url("/fonts/finlandica-bolditalicWeb.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

/* @font-face {
  font-family: "Finlandica";
  src: url("/fonts/Finlandica-MediumItalic.otf") format("opentype"),
    url("/fonts/Finlandica-MediumItalic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
} */

@font-face {
  font-family: "Finlandica";
  src: url("/fonts/finlandica-italicWeb.woff2") format("woff2"),
    url("/fonts/finlandica-italicWeb.woff") format("woff"),
    url("/fonts/finlandica-italicWeb.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

#CookieDeclarationCont {
  grid-column: 1/12 span;
}
#CookieDeclarationCont a {
  text-decoration: underline;
  outline: none;
}

#CookieDeclarationCont a:hover {
  color: var(--color-primary-500);
}
